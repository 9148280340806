<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        If a molecule contains
        <stemble-latex :content="`$\\textbf{${nonBondingDomains}}$`" />
        and
        <stemble-latex :content="`$\\textbf{${bondingDomains}}$`" />
        :
      </p>

      <p class="mb-3 pl-7">a) What is the electron domain geometry of the compound?</p>

      <v-radio-group v-model="inputs.input1" class="pl-14" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="`$\\text{${option.expression}}$`" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-3 pl-7">b) What is the molecular geometry of the compound?</p>

      <v-radio-group v-model="inputs.input2" class="pl-14" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="`$\\text{${option.expression}}$`" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question243c',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
      options1: [
        {expression: 'Trigonal planar', value: 'trigonalPlanar'},
        {expression: 'Square planar', value: 'squarePlanar'},
        {expression: 'Trigonal pyramidal', value: 'trigonalPyramidal'},
        {expression: 'Square pyramidal', value: 'squarePyramidal'},
        {expression: 'Trigonal bipyramidal', value: 'trigonalBipyramidal'},
        {expression: 'Tetrahedral', value: 'tetrahedral'},
        {expression: 'Octahedral', value: 'octahedral'},
        {expression: 'T-shape', value: 'tshape'},
        {expression: 'Bent', value: 'bent'},
        {expression: 'See-saw', value: 'seesaw'},
      ],
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    },
    versionData() {
      switch (this.versionVariable.value) {
        case 1:
          // 1 = trigonal pyramidal
          return {
            nonBondingDomains: '1 non-bonding domain',
            bondingDomains: '3 bonding domains',
          };
        case 2:
          // 2 = bent
          return {
            nonBondingDomains: '2 non-bonding domains',
            bondingDomains: '2 bonding domains',
          };
        case 3:
          // 3 = seesaw
          return {
            nonBondingDomains: '1 non-bonding domain',
            bondingDomains: '4 bonding domains',
          };
        case 4:
          // 4 = tshape
          return {
            nonBondingDomains: '2 non-bonding domains',
            bondingDomains: '3 bonding domains',
          };
        case 5:
          // 5 = square pyramidal
          return {
            nonBondingDomains: '1 non-bonding domain',
            bondingDomains: '5 bonding domains',
          };
        case 6:
          // 6 = square planar
          return {
            nonBondingDomains: '2 non-bonding domains',
            bondingDomains: '4 bonding domains',
          };
        default:
          return {
            nonBondingDomains: null,
            bondingDomains: null,
          };
      }
    },
    nonBondingDomains() {
      return this.versionData.nonBondingDomains;
    },
    bondingDomains() {
      return this.versionData.bondingDomains;
    },
  },
};
</script>
